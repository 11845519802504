import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Header from "../components/Header"

export default () => (
  <Layout>
    <SEO pageTitle="Contact" />
    <Header pageTitle="Contact me" bgImage="backgrounds/header.jpg" />
		<section className="intro-text">
			<div className="container">
				Intro text about contacting me. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Mauris pharetra et ultrices neque ornare aenean euismod elementum nisi. Cursus sit amet dictum sit.
			</div>
		</section>
		<section className="grey-bg">
			<div className="container">
				<form id="contact" name="contact" action="/contact-success" method="POST" data-netlify="true">
					<p>
						<label htmlFor="name">Name</label>
						<input id="name" name="name" type="text" />
					</p>
					<p>
						<label htmlFor="email">Email address</label>
						<input id="email" name="email" type="email" />
					</p>
					<p>
						<label htmlFor="message">Message</label>
						<textarea id="message" name="message" rows="10"></textarea>
					</p>
					<input name="form-name" value="contact" type="hidden"/>
					<button className="button cta" type="submit">Submit</button>
				</form>
			</div>
		</section>
		<section className="body-text">
			<div className="container">
				<p>
					<strong>Birds of Beauty Parlour</strong><br/>
					21 Westdale Crescent<br/>
					Carlton<br/>
					NG4 3JP<br/>
					Nottingham<br/>
					Directions<br/>
				</p>
				<p>
					<strong>tel:</strong> <a href="tel:07812 187531">07812 187531</a><br/>
					<strong>email:</strong> <a href="mailto:hello@birdsofbeautyparlour.co.uk">hello@birdsofbeautyparlour.co.uk</a>
				</p>
				<div className="socials">
					<a href="https://www.facebook.com/Bobp21/"><i className="fab fa-facebook" aria-label="Visit Birds of Beauty on Facebook"></i></a>
					<a href="https://www.instagram.com/birdsofbeautyparlour/"><i className="fab fa-instagram" aria-label="Visit Birds of Beauty on Instagram"></i></a>
				</div>
			</div>
		</section>
		<iframe className="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1201.3363901011958!2d-1.0882752416797723!3d52.97230061020527!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4879c7803de12bc3%3A0x3895511df4e062fa!2sBirds%20of%20Beauty%20Parlour!5e0!3m2!1sen!2suk!4v1616298780001!5m2!1sen!2suk" width="600" height="450" allowfullscreen="" loading="lazy"></iframe>
  </Layout>
)